.df-flexEnd {
    display: flex;
    justify-content: flex-end;
}

.df-center {
    display: flex;
    justify-content: center;
}

.df-centerWarp {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}


.df-spaceBtw {
    display: flex;
    justify-content: space-between;
}

.df-centerY {
    display: flex;
    align-items: center;
    flex-wrap: wrap;  
}

.df-centerXY {
    display: flex;
    justify-content: center;
    align-items: center;
}
.df-centerXYcontenWarp {
    display: flex;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
}

.dfc-center {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.dfc-centerXYpage {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
