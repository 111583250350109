/* .headerApp.ant-layout-sider.ant-layout-sider-light {} */
.closeMenu.ant-layout-sider.ant-layout-sider-light.ant-layout-sider-collapsed {
  flex: 0 0 0px !important;
  max-width: 0px !important;
  min-width: 0px !important;
  width: 0px !important;
}

.ant-table-wrapper.headerTable-color .ant-table-thead > tr > th {
  background-color: rgb(244, 246, 248);
  border-radius: 0px !important;
  color: #434343;
  position: sticky !important;
}

.ant-table-wrapper .ant-table-thead > tr > th,
.ant-table-wrapper .ant-table-tbody > tr > td {
  padding: 8px 8px !important;
}

.ant-table-measure-row {
  display: none;
}

/* lockout btn header */
/* .ant-btn.css-dev-only-do-not-override-12jzuas.ant-btn-round.ant-btn-default.headerLockout { */
.ant-btn-round.ant-btn-default.headerLockout {
  border-radius: 32px;
  padding-inline-start: 5px !important;
  padding-inline-end: 15px !important;
}

/* .ant-btn.css-dev-only-do-not-override-1wazalj.ant-btn-round.ant-btn-default.headerLockout {
  border-radius: 32px;
  padding-inline-start: 5px !important;
  padding-inline-end: 15px !important;
} */
/* lockout btn popover header */
.ant-btn.css-dev-only-do-not-override-12jzuas.ant-btn-text.lockoutBtn {
  padding: 0;
  display: flex;
  align-items: center;
  width: 100%;
}

.ant-table-content::-webkit-scrollbar {
  height: 5px;
}

.ant-table-content::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}

.ant-table-content::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 100px;
}

.ant-input,
.ant-select-selector {
  border-radius: 8px !important;
}

.ant-input-affix-wrapper.loginForm.ant-input-affix-wrapper-lg.css-dev-only-do-not-override-1wazalj,
.ant-input-affix-wrapper.ant-input-password.loginForm.ant-input-password-large.ant-input-affix-wrapper-lg.css-dev-only-do-not-override-1wazalj {
  border-radius: 25px !important;
}
