/* login */
.loginPage {
  height: 100vh;
  background-color: #ffffff;
  padding: 24px;
}
.loginIllust {
  width: 100%;
  height: 35vw;
}
.loginContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  padding-right: 2%;
}
.loginHeader {
  font-size: 3vw;
  font-weight: bold;
  margin-bottom: 20px;
}
.forgotPass {
  display: flex;
  justify-content: flex-end;
}

/* new password */
.newPassContent {
  display: flex;
  flex-direction: column;
  align-content: center;
  flex-wrap: wrap;
  padding-left: 5%;
  padding-right: 2%;
}

/* header */
.headerApp {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  z-index: 4;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.headerLockout {
  height: fit-content;
  width: fit-content;
}

.lockoutDetail {
  padding: 10px 0px;
  border-bottom: 1px solid #f0f0f0;
}

.lockoutBtn {
  margin-top: 10px;
  width: 100%;
}

/* page content */
.pageTitle {
  font-size: 24px;
  font-weight: bold;
}

.newPassDisplay1 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 24px; */
}
.newPassDisplay2 {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 24px; */
}

.newPassIllust {
  width: 100%;
  height: 45vw;
}

.buttonHeader {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-bottom: 10px;
}

.breadcrumbStyle {
  transition: all 0.25s ease;
}

.breadcrumbStyle:hover {
  transform: translateX(6px);
  transition: all 0.25s ease;
}

.listData-card {
  box-shadow: rgba(0, 0, 0, 0.05) 0px 20px 27px;
}

.headerTable-color {
  background-color: white;
  border-radius: 20px;
}

.tableBody {
  color: #434343;
}

.searchCard {
  background-color: white;
  padding: 24px;
  border-radius: 20px 20px 0px 0px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 20px 27px;
}

.modalHeader {
  border-bottom: 1px;
  border-bottom-color: rgba(145, 158, 171, 0.2);
  border-bottom-style: solid;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  line-height: 30px;
  color: #595959;
}

.modalCreate {
  width: 700px !important;
}

.searchBtnOrg {
  display: flex;
  justify-content: flex-end;
}

.tableBottom {
  display: flex;
  justify-content: flex-end;
  background-color: white;
  border-radius: 0pc 0px 20px 20px;
  padding: 20px;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
}

.loadingOverlay {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.7);
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 999;
}

.spinLoader {
  width: 100px;
  height: 100px;
  display: grid;
  border: 8px solid #0000;
  border-radius: 50%;
  border-color: #f0f5ff #0000;
  animation: s6 1s infinite linear;
}
.spinLoader::before,
.spinLoader::after {
  content: "";
  grid-area: 1/1;
  margin: 4px;
  border: inherit;
  border-radius: 50%;
}
.spinLoader::before {
  border-color: #7eb2dd #0000;
  animation: inherit;
  animation-duration: 0.5s;
  animation-direction: reverse;
}
.spinLoader::after {
  margin: 16px;
}

@keyframes s6 {
  100% {
    transform: rotate(1turn);
  }
}

.linearloader {
  height: 4px;
  width: 100%;
  background: linear-gradient(#1890ff 0 0), linear-gradient(#1890ff 0 0),
    #e4e4ed;
  background-size: 60% 100%;
  background-repeat: no-repeat;
  animation: linearLoading 3s infinite;
}
#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 1001;
  cursor: pointer;
}

.text-overlay-center {
  display: flex;
  align-items: center;
  color: white;
  height: 100vh;
  justify-content: center;
}

@keyframes linearLoading {
  0% {
    background-position: -150% 0, -150% 0;
  }
  66% {
    background-position: 250% 0, -150% 0;
  }
  100% {
    background-position: 250% 0, 250% 0;
  }
}

@media (max-width: 600px) {
  .forgotPass {
    justify-content: flex-start;
  }
  .searchBtnOrg {
    display: flex;
    justify-content: flex-start;
    padding-top: 10px;
  }
}

@media (max-width: 899px) {
  .loginContent,
  .newPassContent {
    padding-left: 5%;
    padding-right: 5%;
  }

  .newPassContent,
  .newPassBtn {
    display: flex;
    justify-content: center;
  }
}
@media (min-width: 900px) {
  .loginHeader {
    font-size: 3vw;
  }
}
@media (max-width: 900px) {
  .loginHeader {
    font-size: 5vw;
    margin-bottom: 10px;
    margin-top: 5px;
  }
  .newPassDisplay2 {
    display: none;
  }
}

@media (width: 900px) {
  .newPassDisplay2 {
    display: flex;
  }
}

@media (min-width: 900px) {
  .loginContent {
    padding-right: 10%;
  }

  .newPassDisplay1 {
    display: none;
  }
}
