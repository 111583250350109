@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@200;300;400;600&display=swap");

* {
  box-sizing: content-box !important;
  box-sizing: border-box !important;
  font-family: "Prompt", sans-serif !important;
}
body {
  font-size: 16px;
  padding: 0;
  margin: 0;
  color: #434343 !important;
}

#root {
  height: 100% !important;
}

body,
html {
  margin: 0;
  height: 100%;
  scroll-behavior: smooth !important;
}

.page-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.main-content {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch !important;
  width: 100%;
  height: -webkit-fill-available;
  background-color: #f5f5f5;
}

.pageSider {
  flex: 1;
  display: flex;
  overflow-y: hidden;
}

.pdPage {
  padding: 24px;
}

.menuStyle {
  background-color: #1890ff;
}

.sidebarApp {
  height: 100vh;
}

.main-content::-webkit-scrollbar {
  width: 5px;
}

.main-content::-webkit-scrollbar-track {
  background-color: #f5f5f5;
  /* border-radius: 100px; */
}

.main-content::-webkit-scrollbar-thumb {
  background-color: #bfbfbf;
  border-radius: 100px;
}

.primaryColor {
  color: #1890ff;
}

.radiusBtn,
.primaryBtnAnimate,
.dangerOutlAnimate {
  border-radius: 8px !important;
}

.primaryRoundBtnAnimate:hover {
  -webkit-box-shadow: 0 10px 20px -10px #1890ff;
  box-shadow: 0 10px 20px -10px #1890ff;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.primaryBtnAnimate:hover {
  -webkit-box-shadow: 0 10px 20px -10px #1890ff;
  box-shadow: 0 10px 20px -10px #1890ff;
  -webkit-transform: translateY(-3px);
  transform: translateY(-3px);
}

.dangerOutlAnimate:hover {
  background-color: #ff4d4f;
  color: white !important;
  transition: all 0.25s ease, background-color 0.25s ease 0.25s;
}
